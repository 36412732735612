.mx_LiiibreHomeButton {
    flex: 0 0 auto;
    border-radius: 8px;
    position: relative;
    margin: 12px auto;
    color: $secondary-content;
    min-width: 32px;
    min-height: 32px;
    line-height: 32px;

    &.expanded {
        margin-left: 20px;
        padding-left: 44px; /* align with toggle collapse button text */
        padding-right: 8px;
    }

    &::before {
        content: " ";
        position: absolute;
        width: 32px;
        height: 32px;
        left: 0;
        background-image: url("$(res)/img/liiibre-icons/liiibre.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px;
    }

    &:not(.expanded):hover {
        background-color: $quaternary-content;
    }
}
